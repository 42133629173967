<template>
  <CustomDrawer
    :model-value="show"
    :style-config="{
      height: '80%',
    }"
    :attach="attach"
    @close="onClose"
  >
    <AllDiscountDetailContent
      @close="onClose"
    />
  </CustomDrawer>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from '../../../hooks/useStore'
import CustomDrawer from '../../common/CustomDrawer.vue'
import AllDiscountDetailContent from './AllContent.vue'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'

const { state, commit } = useStore()
const { registerModalState, updateModalState } = useUpdateModalState()
const isLoaded = ref(false)
const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)
const show  = computed(() => isLoaded.value && showDiscountDetails.value)
const attach = computed(() => state.moduleName === 'mini_cart' ? '.mini-cart__footer' : '.j-cart-checkout')

watch(() => show.value, (val) => {
  if (val) {
    registerModalState()
  } else {
    updateModalState()
  }
})

const onClose = () => {
  commit('changeAllDiscountDetailDrawerParams', {
    show: false,
  })
}

onMounted(() => {
  commit('updateState', {
    key: 'loadingShow',
    value: false,
  })
  isLoaded.value = true
})
</script>
